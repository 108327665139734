import { useRouter } from "next/navigation";

import { Button } from "@/components/atoms";
import Vouchers from "@/lib/checkout/Vouchers";
import useLocation from "@/lib/hooks/useLocation";
import type { CartHook } from "@/lib/hooks/useSelection/cart/useCart";
import useCart from "@/lib/hooks/useSelection/cart/useCart";
import {
  convertCartToGTagItem,
  eventBeginCheckout
} from "@/lib/utils/Analytics/Functions";

import styles from "./cart-summary.module.css";
const CartSummary = ({
  summary,
  modal,
  onlyData = false
}: {
  summary: CartHook["cart"]["summary"] | undefined;
  modal?: CartHook["modal"];
  onlyData?: boolean;
}) => {
  const router = useRouter();
  const { currency } = useLocation();
  const { cart } = useCart();
  const goToCheckout = () => {
    if (modal) modal?.toggleModal();
    router.push("/checkout");
    eventBeginCheckout({
      currency: currency || "",
      value: cart.summary?.prices.totalAsNumber,
      items: cart.items.map((item) => convertCartToGTagItem(item))
    });
  };

  const closeModal = () => {
    if (modal) modal?.toggleModal();
  };

  const emptyCart = !cart.summary?.totalQuantity;

  return (
    <div className={styles.summary}>
      {!modal && !onlyData && <Vouchers />}
      <div className={styles.subtotals}>
        <span>
          <p>SUBTOTAL</p>
          <p>{summary?.prices?.total ?? "0,-"}</p>
        </span>
        {!!summary?.discount?.[0]?.priceOff && (
          <span>
            <p>DISCOUNT</p>
            <p>{summary?.discount?.[0]?.priceOff ?? "0,-"}</p>
          </span>
        )}

        <span>
          <p>SHIPPING</p>
          <p>{summary?.prices?.shipping ?? "0,-"}</p>
        </span>
      </div>

      <span className={styles.grandtotal}>
        <p>TOTAL</p>
        <p>{summary?.prices?.grandTotal ?? "0,-"}</p>
      </span>
      {modal && (
        <div className={styles.cart__actions}>
          <Button color="transparent" outlined grow onClick={closeModal}>
            <span className="uppercase">Continue shopping</span>
          </Button>
          <Button
            color="black"
            textColor="white"
            grow
            shadow
            disabled={emptyCart}
            onClick={goToCheckout}
          >
            <span className="uppercase">Checkout</span>
          </Button>
        </div>
      )}
      {!onlyData && (
        <p className={styles["shipping-text"]}>
          Free shipping over 2000 nok within
          <strong className={styles.shipping_text__country}>Scandinavia</strong>
        </p>
      )}
    </div>
  );
};

export default CartSummary;

"use client";
import * as Tabs from "@radix-ui/react-tabs";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";

import { Button, ButtonLink, Heading, ProductCard } from "@/components/atoms";
import Preamble from "@/components/atoms/Preamble/Preamble";
import { convertProductCardToCartItem } from "@/lib/centra/convertToCartItem/convertToCartItem";
import type { ProductCard as PC } from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import useAddToCart from "@/lib/hooks/useSelection/cart/useAddToCart";
import useWishlist from "@/lib/hooks/useWishlist";

import { useCart } from "@/lib/hooks/useSelection";
import styles from "./wishlist.module.css";

const fecthPCbyID = async (ids: string[]) => {
  const justVariant = ids?.flatMap((id) => id?.split("-")[0]);

  const fetchIds = async (ids) => {
    const res = await fetch("/api/products/product-card", {
      method: "POST",
      body: JSON.stringify({
        ids
      })
    });
    return res.json();
  };

  const data = await fetchIds(justVariant);

  const pcs = data.filter((pc) => !!pc) as unknown as NonNullable<PC>[];

  const pcWithSize = pcs.map((pc, i) => {
    const size = pc.description.size.find((s) => s.itemId === ids[i]);
    return {
      ...pc,
      size
    };
  });

  return pcWithSize;
};

const Wishlist = ({ setTab }) => {
  const { ids, remove, clearAll } = useWishlist();

  const { addToCart } = useAddToCart();
  const { modal } = useCart();

  const { data } = useQuery(
    ["wishlist", ids],
    async () => await fecthPCbyID(ids)
  );

  clearAll();

  const move = (product) => {
    remove(product.size.itemId);
    addToCart.mutate({
      itemId: product.size.itemId,
      cartItem: convertProductCardToCartItem(product, product.size)
    });
  };

  if (ids.length === 0) {
    return (
      <Tabs.Content className={clsx(styles["cart-content"])} value="wishlist">
        <div className={styles["cart-empty"]}>
          <Preamble>Wishlist</Preamble>
          <Heading as="h3" styledAs="h4">
            Your wishlist is empty
          </Heading>
          <div className={styles.buttons}>
            <Button onClick={() => setTab("cart")} outlined>
              Go to cart
            </Button>
            <ButtonLink
              href="/products"
              color="black"
              textColor="white"
              onClick={modal.toggleModal}
            >
              Go shopping
            </ButtonLink>
          </div>
        </div>
      </Tabs.Content>
    );
  }

  return (
    <Tabs.Content className={styles.container} value="wishlist">
      {data?.map((pc, i) => {
        const size = pc?.size?.label;

        return (
          <div key={pc.id + "-pc-wl" + i}>
            <ProductCard key={pc.id} product={pc} inWishlist />

            {size && <span className={styles.size}>Size: {size}</span>}
          </div>
        );
      })}
    </Tabs.Content>
  );
};

export default Wishlist;
